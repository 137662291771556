import React from 'react'
import styled from 'styled-components'

import {modularScale} from '../utils/helpers'
import metworx from '../images/metworx.png'

const Layout = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-wrap: nowrap;
	height: 100%;
`

export default () => (
	<Layout>
		<img src={metworx} alt='Metworx' style={{maxWidth: `${modularScale(17)}`}} />
		<h1>You have found a page that doesn't exist!</h1>
		<h3>Please report this to the Metworx Team by:: </h3>
		<ol>
			<li>Navigating to the Metworx ZenDesk: https://metworx-help.zendesk.com/</li>
			<li>Submitting a Support Ticket.</li>
		</ol>
	</Layout>
)